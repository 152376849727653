$(function() {
    var slidey = $('.slider').unslider({
    	dots: true, 
      fluid: true,
      delay: 5000,
      complete: function (e){
        var index = $('.slider .dot.active').index();
        $('.dots li').removeClass('active').eq(index).addClass('active');
        if (index == $(".slider li").length/2-1)
        {
          var data = slidey.data('unslider');
          data.speed = 0;
          data.move(0, function () {});
          data.speed = 500;
          $('.dots li').removeClass('active').eq(0).addClass('active');
        }
      }
    });

    $('.unslider-arrow').click(function() {
        var fn = this.className.split(' ')[1];
        
        //  Either do unslider.data('unslider').next() or .prev() depending on the className
        unslider.data('unslider')[fn]();
     });
});